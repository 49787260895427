import React from "react";
import PropTypes from "prop-types";
import { useContent } from "fusion-content";
import { useAppContext } from "fusion-context";
import get from "lodash.get";
import { Header } from "@washingtonpost/site-header";
import { styled } from "@washingtonpost/wpds-ui-kit";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { useMultiView } from "~/shared-components/MultiViewContext";
import contentFilter from "../../../../content/filters/site-subs-offer";
import navContentFilter from "../../../../content/filters/site-navigation";
import accountContentFilter from "../../../../content/filters/site-accountbar";

const AdminStyles = styled("div", {
  variants: {
    isAdmin: {
      true: {
        transform: "translateY(0px)",
        overflow: "hidden"
      }
    }
  }
});

export const useNavData = () => {
  const ctaOfferData = useContent({
    source: "site-homepage",
    filter: contentFilter
  }) || { site: {} };

  const sideNavData = useContent({
    source: "site-menu",
    query: { hierarchy: "WebNav" },
    filter: navContentFilter
  });

  const sideNavFeaturedData = useContent({
    source: "site-menu",
    query: { hierarchy: "WebNavFeatured" },
    filter: navContentFilter
  });

  const accountSideBarData = useContent({
    source: "site-account-sidebar",
    filter: accountContentFilter
  });

  const { globalContent } = useAppContext();

  // TODO do we need to have it fall back to something for homepage?
  const arcId = get(globalContent, "_id");

  if (!ctaOfferData || !sideNavData || !accountSideBarData) {
    return null;
  }

  return {
    ctaOfferData,
    sideNavData,
    sideNavFeaturedData,
    accountSideBarData,
    arcId
  };
};

const BaseFusionHeader = ({ data, isFixed = true }) => {
  const { isAdmin } = useAppContext();
  const { isNoNav } = useAssemblerContext();
  const { isMultiView } = useMultiView();
  if (!data) return null;
  if (isNoNav) return null;

  const {
    ctaOfferData,
    sideNavData,
    sideNavFeaturedData,
    accountSideBarData,
    arcId
  } = data;

  return (
    <AdminStyles isAdmin={isAdmin}>
      <Header
        arcId={arcId}
        adotData={{ selectedPromoKey: "hd_sub_promo" }}
        offerData={ctaOfferData}
        leftNavData={sideNavData}
        leftNavFeaturedData={sideNavFeaturedData}
        rightNavData={accountSideBarData}
        fixed={!isAdmin && isFixed}
        multiview={isMultiView}
      ></Header>
    </AdminStyles>
  );
};

BaseFusionHeader.propTypes = {
  data: PropTypes.object,
  isFixed: PropTypes.bool
};

const ArticleHeader = ({ isFixed }) => {
  return <BaseFusionHeader data={useNavData()} isFixed={isFixed} />;
};

ArticleHeader.propTypes = {
  isFixed: PropTypes.bool
};

export { ArticleHeader };

export default ArticleHeader;
